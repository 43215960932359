<template>
     <section class="page_title big  pdt_80  pdb_40">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 text-center">
                        <!---------col----------->
                        <div class="content_box">
                           <h6>Recent from the Briefs</h6>
                           <h1>  Latest Briefs</h1>
                           <ul class="bread_crumb text-center">
                              <li class="bread_crumb-item"><a href="#">Home</a></li>
                              <li class="bread_crumb-item"><a href="#">Products</a></li>
                              <li class="bread_crumb-item active">Briefs</li>
                           </ul>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>
            <!-----------page_title-end-------------->
            <!-----------blog_v1--------------->
            <section class="blog_v1 pdt_80  pdb_100">
               <div class="container">
                  <div class="featured_post">
                     <div class="row">
                        <!---------row----------->
                        <div class="col-lg-12">
                           <!---------blog_box----------->
                           <div class="blog_box type_two">
                              <div class="image_box">
                                 <img src="@/assets/image/products/private_sector_engagement_in_the_COVID-19_response.jpg" style="height:100%; width:100%; object-fit:cover; object-position:center"  class="img-fluid" alt="img" />
                                 <div class="overlay ">
                                    <a href="@/assets/image/products/private_sector_engagement_in_the_COVID-19_response.jpg" data-fancybox="gallery " data-caption=" ">
                                    <span class="linearicons-picture2"></span>
                                    </a>
                                 </div>
                              </div>
                              <div class="content_box clearfix">
                                 <h2><a href="#">Private sector engagement in the COVID-19 response: experiences and lessons from the Democratic Republic of Congo, Nigeria, Senegal and Uganda</a></h2>
                              
                                 <p>This was a multi-country cross-sectional study conducted in the Democratic Republic of Congo, Nigeria, Senegal and Uganda between November 2020 and March 2021 to assess responses to the COVID-19 pandemic. </p>
                              </div>
                              <div style="padding:30px;" ><p> We conducted a scoping literature review and key informant interviews (KIIs) with private and public health sector stakeholders. The literature reviewed included COVID-19 country guidelines and response plans, program reports, and peer-reviewed and non-peer-reviewed publications. KIIs elicited information on country approaches and response strategies, specifically the engagement of the private sector in any of the strategic response operations.</p>
                                 <a href="/assets/image/products/private_sector_engagement_in_the_COVID-19_response.pdf" target="_blank" class="read_more tp_one text-center"> Download <span class="linearicons-arrow-down"></span></a>
                              </div>
                           </div>
                           
                           <!---------blog_box end----------->
                        </div>
                        <!---------row-end---------->
                     </div>
                  </div>
                  <div class="row">
                     <!---------row----------->
                      <div class="col-lg-4">
                        <!---------blog_box----------->
                        <div class="blog_box type_one">
                           <div class="image_box">
                              <img src="@/assets/image/resources/blog-2-1.jpg" style="height:300px; width:100%; object-fit:cover; object-position:center" class="img-fluid" alt="img" />
                              <div class="overlay">
                                 <a href="@/assets/image/resources/blog-2-1.jpg" data-fancybox="gallery " data-caption=" ">
                                    <span class="linearicons-picture2"></span>
                                 </a>
                              </div>
                           </div>
                           <div class="content_box">
                              <h2><a href="#">Guidance on the COVID-19 vaccine “MIX AND MATCH SCHEDULE AND BOSTER DOSES”.</a></h2>
                              
                              <p>The COVID-19 guide from the Ministry of Health Uganda addressed directors of national referral hospitals, directors of regional referral hospitals, and district health officers.?</p>
                              <a target="_blank" href="/assets/image/products/Guidance_on_the_COVID_19 vaccine_MIX_AND_MATCH_SCHEDULE_AND_BOSTER_DOSES.pdf" class="read_more tp_one">Download<span class="linearicons-arrow-down"></span></a>
                           </div>
                        </div>
                        <!---------blog_box end----------->
                     </div>

                     <div class="col-lg-4">
                        <!---------blog_box----------->
                        <div class="blog_box type_one">
                           <div class="image_box">
                              <img src="@/assets/image/products/makoko.jpeg" style="height:300px; width:100%; object-fit:cover; object-position:center" class="img-fluid" alt="img" />
                              <div class="overlay">
                                 <a href="@/assets/image/products/makoko.jpeg" data-fancybox="gallery " data-caption=" ">
                                    <span class="linearicons-picture2"></span>
                                 </a>
                              </div>
                           </div>
                           <div class="content_box">
                              <h2><a href="#">A cross-sectional study was conducted among residents of an urban slum in Makoko, Lagos-Nigeria.</a></h2>
                              
                              <p>Challenges of implementing non-pharmaceutical-interventions to prevent COVID-19 in an urban-slum in Lagos-Nigeria.</p>
                              <a target="_blank" href="/assets/image/products/a_cross_sectional_study_was_conducted_in_makoko.pdf" class="read_more tp_one">Download<span class="linearicons-arrow-down"></span></a>
                           </div>
                        </div>
                        <!---------blog_box end----------->
                     </div>
                     <div class="col-lg-4">
                        <!---------blog_box----------->
                        <div class="blog_box type_one">
                           <div class="image_box">
                              <img src="@/assets/image/products/Omicron.jpg" style="height:300px; width:100%; object-fit:cover; object-position:center" class="img-fluid" alt="img" />
                              <div class="overlay ">
                                 <a href="@/assets/image/products/Omicron.jpg" data-fancybox="gallery " data-caption=" ">
                                 <span class="linearicons-picture2"></span>
                                 </a>
                              </div>
                           </div>
                           <div class="content_box">
                              <h2><a href="#">WHO HQ Technical brief and priority actions on Omicron</a></h2>
                              
                              <p>Enhancing Readiness for Omicron (B.1.1.529): Technical Brief and Priority Actions for Member States World Health Organization HQ 17 December 2021 (updated from the last version published on 10 December 2021)</p>
                              <a target="_blank" href="/assets/image/products/WHO_HQ_Technical_brief_and_priority_actions_on_Omicron.pdf" class="read_more tp_one">Download<span class="linearicons-arrow-down"></span></a>
                           </div>
                        </div>
                        <!---------blog_box end----------->
                     </div>
                     <div class="col-lg-4">
                        <!---------blog_box----------->
                        <div class="blog_box type_one">
                           <div class="image_box">
                              <img src="@/assets/image/products/ethiopia.png" style="height:300px; width:100%; object-fit:cover; object-position:center"   class="img-fluid" alt="img" />
                              <div class="overlay">
                                 <a href="@/assets/image/products/ethiopia.png" data-fancybox="gallery " data-caption=" ">
                                    <span class="linearicons-picture2"></span>
                                 </a>
                              </div>
                           </div>
                           <div class="content_box">
                              <h2><a href="#">Qualitative Study on Health System Response to COVID-19 at Subnational Level in Ethiopia</a></h2>
                              
                              <p>We conducted a qualitative study using key informant interviews. A total of 59 health leaders
and practitioners across Ethiopia’s 10 regions and 2 administrative cities were purposively selected.</p>
                              <a  target="_blank" href="/assets/image/products/Qualitative_Study_on_Health_System_Response_to_COVID_19_at_Subnational_Level_in_Ethiopia.pdf" class="read_more tp_one"> Download<span class="linearicons-arrow-down"></span></a>
                           </div>
                        </div>
                        <!---------blog_box end----------->
                     </div>
                     <!----------row-end---------->
                  </div>
            
               </div>
            </section>
            <!-----------blog_v1-end-------------->
       
</template>
